import React from 'react'
// Types
import { SectionBase, SectionStatsList } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading, Image, Text } from '../../../atoms'

export interface StatsListProps extends SectionBase {
  data: SectionStatsList
}

const StatsList: React.FC<StatsListProps> = ({ data }) => {
  const { id, sectionId, headline, stats, backgroundColor } = data

  return (
    <Box
      as={'section'}
      id={sectionId || id}
      className={'p-section'}
      position={'relative'}
      py={backgroundColor ? [80, 80, 120] : undefined}
      my={!backgroundColor ? [80, 80, 120] : undefined}
      bg={backgroundColor ? backgroundColor.hex : 'transparent'}
    >
      <Grid>
        <Box mb={[8, 9, 10]}>
          <Row center={'xs'}>
            <Col xs={12} md={8}>
              <Heading as='h4' textAlign={'center'}>
                {headline}
              </Heading>
            </Col>
          </Row>
        </Box>
        <Row around={'xs'}>
          {stats.map((s) => {
            return (
              <Col key={s.id} xs={12} sm={4} md={3}>
                <Box mb={[7, 8, 9]} maxWidth={320} mx={'auto'}>
                  <Box borderRadius={['medium', 'medium', 'large']} overflow={'hidden'}>
                    <Image
                      draggable={false}
                      alt={s.asset.alt || ''}
                      image={s.asset.gatsbyImageData}
                      style={{ overflow: 'hidden', borderRadius: '32px' }}
                      imgStyle={{ borderRadius: '32px' }}
                    />
                  </Box>
                  <Box mt={[4, 5, 6]}>
                    <Text
                      fontFamily={'hoves'}
                      fontSize={['32px', '40px', '56px']}
                      fontWeight={'semi'}
                      lineHeight={['40px', '48px', '64px']}
                      textAlign={'center'}
                    >
                      {s.headline}
                    </Text>
                    <Text textAlign={'center'}>{s.subHeadline}</Text>
                  </Box>
                </Box>
              </Col>
            )
          })}
        </Row>
      </Grid>
    </Box>
  )
}

export default StatsList
